import NavigationTab from "../../../components/NavigationTab";
import styled from "styled-components";
import { useContext, useEffect, useState } from 'react'
import { AuthContextOwner } from "../../../context/AuthOwner";
import { colorPrimario } from "../../../components/UI/variables";
import HeaderLoginOwner from "../../../components/HeaderLoginOwner";

const StyledDivContenedor=styled.div`
    padding: 96px 16px 16px 16px;    
`
const StyledDiv=styled.div`
    display: flex;
    flex-direction: column;
`
const StyledP=styled.p`
    text-align: center;
    font-weight: bold;
    margin: 24px 0 8px 0;
`
const ContenidoLista=styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    ul{
        margin:0;
        padding: 0px 8px 0px 24px;
    }
`
const StyledDivCuentas=styled.div`
    width: 312px;
    display:flex;
    justify-content:space-between;
    p{
        margin: 0 0 0 0;
    }
`
const PConcepto=styled.p`
    width: 220px;
    display: flex;
`
const ContenidoPlan=styled.div`
    padding-bottom: 16px;
    border: 2px solid ${colorPrimario}; 
    display: flex;
    flex-direction: column;
    align-items: center;
`
const PDiasTerminados = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: red;
    margin: 16px 0 0 0;
    text-align: center;
`
const DivEstadoSuscripcion=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 0 0;
`
const PDias=styled.p`
    font-size: 20px;
    font-weight: bold;
    color: green;
    margin: 0 0 0 0;
`
const PrecioP=styled.p`
    display: flex;
    width: 100px;
    height: 60px;
    font-size: 14px;
    align-items: center;
    text-align: center;
    border: 1px dashed gray;
    margin: 0;
`

const SuscripcionOwner=()=>{
    const { currentUser } = useContext(AuthContextOwner);
    const limiteHabitaciones=currentUser[0].limiteHabitaciones
    const diasTotalAcumulados=currentUser[0].diasTotalAcumulados
    const planSuscripcion=currentUser[0].planSuscripcion
    const suscripcionActiva=currentUser[0].suscripcionActiva
    const precio=currentUser[0].precio
    const fechaRenovacionSuscripcion=currentUser[0].fechaRenovacionSuscripcion.toDate()

    const[precioTotal, setPrecioTotal]=useState("")
    useEffect(()=>{
        setPrecioTotal(precio*limiteHabitaciones*planSuscripcion)
    },[limiteHabitaciones, precio, planSuscripcion])

    // Calcula la diferencia en días entre dos fechas
    const calcularDiferenciaEnDias = (fechaInicio, fechaFin) => {
        const unDiaEnMilisegundos = 1000 * 60 * 60 * 24;
        const diferenciaEnMilisegundos = fechaFin - fechaInicio;
        return Math.ceil(diferenciaEnMilisegundos / unDiaEnMilisegundos);
    };
    //*****************************FUNCION PARA SUMAR DIAS A UNA FECHA***************************** */
    const sumarDias = (fecha, dias) => {
        const fechaSumada = new Date(fecha);
        fechaSumada.setDate(fechaSumada.getDate() + dias);
        return fechaSumada;
    };  
    
    const hoy= sumarDias(new Date(), 0); // cambiar el 0 si se quiere adelantar el tiempo para hacer pruebas
    const diasTranscurridos=calcularDiferenciaEnDias(fechaRenovacionSuscripcion,hoy)
    const diasFaltantes=diasTotalAcumulados-diasTranscurridos;

    return(
        <section>
            <HeaderLoginOwner/>
            <StyledDivContenedor>
                <h1>Mi Suscripcion:</h1>
                <StyledDiv>
                    <ContenidoPlan>
                        {diasFaltantes<0 
                            ?
                            <DivEstadoSuscripcion>
                                <PDiasTerminados>Ups! se terminaron tus dias de suscripcion</PDiasTerminados>
                                <p>{`vencio el: ${sumarDias(fechaRenovacionSuscripcion,diasTotalAcumulados).toLocaleDateString()}`}</p>
                            </DivEstadoSuscripcion>
                            :
                            (
                                diasFaltantes>=0 && suscripcionActiva==false
                                    ?
                                    <DivEstadoSuscripcion>
                                        <PDiasTerminados>Suscripcion inactiva, comunicate con el administrador 👀🤔</PDiasTerminados>
                                    </DivEstadoSuscripcion>
                                    :
                                    <DivEstadoSuscripcion>
                                        <StyledP>Te quedan:</StyledP>
                                        <PDias> {diasFaltantes} dias</PDias>
                                        <p>{`vence el: ${sumarDias(fechaRenovacionSuscripcion,diasTotalAcumulados).toLocaleDateString()}`}</p>
                                    </DivEstadoSuscripcion>
                            )
                        }
                        <StyledP>Tu suscripcion incluye:</StyledP>
                        <ContenidoLista>
                            <ul>
                                <li>Registro de Propiedades</li>
                                <li>Registro de Habitaciones</li>
                                <li>Registro de Inquilinos</li>
                                <li>Control de Pagos</li>
                            </ul>
                            <PrecioP>S/. {precio}.00  x Habitacion/ cada mes</PrecioP>
                        </ContenidoLista>
                        <StyledP>Datos del utimo pago realizado:</StyledP>
                        <StyledDivCuentas>
                            <PConcepto>Habitaciones Administradas:</PConcepto>
                            <p>{limiteHabitaciones} Hab.</p>
                        </StyledDivCuentas>
                        <StyledDivCuentas>
                            <PConcepto>Precio mensual/Habitacion:</PConcepto>
                            <p>S/. {precio}.00</p>
                        </StyledDivCuentas>
                        <StyledDivCuentas>
                            <PConcepto>Meses contratados:</PConcepto>
                            <p>{planSuscripcion} mes(es)</p>
                        </StyledDivCuentas>
                        <StyledDivCuentas>
                            <PConcepto><strong>Tu último pago fue de:</strong></PConcepto>
                            <p><strong>S/. {precioTotal}.00</strong></p>
                        </StyledDivCuentas>
                    </ContenidoPlan>
                </StyledDiv>
            </StyledDivContenedor>
            <NavigationTab/>
        </section>
    )
}
export default SuscripcionOwner;