import React, { useEffect, useState } from "react";
import CardPropietario from "../CardPropietario";
import PropietarioAdministrado from "../PropietarioAdministrado";
import flechaAbajo from "../../assets/iconos/flechaAbajo.svg";
import styled from "styled-components";
import { colorSecundario } from "../UI/variables";
import BarraBusqueda from "../BarraBusqueda";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import appFirebase from "../../firebase";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;
  width: 100%;
`;
const DivSeleccionHabitacion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 276px;
  width: 100%;
  margin-bottom: 16px;
  border: dashed 2px gray;
  p {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: gray;
    margin: 0 0 4px 0;
  }
  img {
    width: 36px;
    height: 36px;
  }
`;
const ButtonPropietario = styled.button`
  margin-top: 16px;
  padding: 0px;
  border: none;
  background-color: ${colorSecundario};
  &:hover {
    border: solid 3px black;
    font-size: 20px;
    font-weight: bold;
  }
  &:active {
    border: solid 3px black;
    font-size: 20px;
    font-weight: bold;
  }
`;
const ListaPropietarios = () => {
  const db = getFirestore(appFirebase);
  const [indexPropietarioSeleccionado, setIndexPropietarioSeleccionado] = useState(null);
  const [propietariosFiltrados, setPropietariosFiltrados] = useState([]);
  const [propiedadesOwner,setPropiedadesOwner] = useState([])
  const [habitacionesOwner,setHabitacionesOwner] = useState([])
  const [propietarioSeleccionado, setPropietarioSeleccionado] = useState(null)

  // *************************FUNCION PARA LLAMAR PROPIEDADES DEL PROPIETARIO SELECCIONADO********************************* */
  const llamarPropiedades = (uid)=>{
    const q = query(collection(db, "propiedades"), where("idPropietario", "==", `${uid}`))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const propiedadesFirebase = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idPropiedad: doc.id,
      }));
      setPropiedadesOwner(propiedadesFirebase);
    });
    return () => unsubscribe();
  }
  
  //*****************************FUNCION PARA LLAMAR HABITACIONES DEL PROPIETARIO SELECCIONADO******************************* */
  const llamarHabitaciones = (uid)=>{
    const q = query(collection(db, "habitaciones"), where("idPropietario", "==", `${uid}`))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const habitacionesFirebase = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idHabitacion: doc.id,
      }));
      setHabitacionesOwner(habitacionesFirebase);
    });
    return () => unsubscribe();
  }

  //*****************************************OBTENER PROPIETARIOS FILTRADOS DESDE BARRABUSQUEDA******************************* */
  const obtenerPropietariosFiltrados = (resultado) => {
    setPropietariosFiltrados(resultado);
  };

  //*****************************************IDENTIFICAR AL PROPIETARIO SELECCIONADO***************************************** */
  const seleccionarPropietario = (i) => {
    setIndexPropietarioSeleccionado(i);
  };
  useEffect(()=>{
    if(indexPropietarioSeleccionado !== null){
      setPropietarioSeleccionado(propietariosFiltrados[indexPropietarioSeleccionado])
    }
  },[indexPropietarioSeleccionado, propietariosFiltrados])

  //********************************LLAMAMOS PROPIEDADES Y HABITACIONES DEL PROPIETARIO SELECCIONADO************************* */
  useEffect(()=>{
    if(propietarioSeleccionado){
      llamarPropiedades(propietarioSeleccionado.idUsuario)
      llamarHabitaciones(propietarioSeleccionado.idUsuario)
    }
  },[propietarioSeleccionado])

  return (
    <StyledSection>
      {propietarioSeleccionado ? (
        <PropietarioAdministrado
          key={propietarioSeleccionado.idUsuario}
          idUsuario={propietarioSeleccionado.idUsuario}
          suscripcionActiva={propietarioSeleccionado.suscripcionActiva}
          nombre={propietarioSeleccionado.nombre}
          apellido={propietarioSeleccionado.apellido}
          dni={propietarioSeleccionado.dni}
          celular={propietarioSeleccionado.celular}
          correo={propietarioSeleccionado.correo}
          limiteHabitaciones={propietarioSeleccionado.limiteHabitaciones}
          planActual={propietarioSeleccionado.planSuscripcion}
          diasTotalAcumulados={propietarioSeleccionado.diasTotalAcumulados}
          fechaInicioSuscripcion={propietarioSeleccionado.fechaInicioSuscripcion}
          fechaRenovacionSuscripcion={propietarioSeleccionado.fechaRenovacionSuscripcion}
          propiedadesOwner={propiedadesOwner}
          habitacionesOwner={habitacionesOwner}
          bloqueado={propietarioSeleccionado.bloqueado}
          precioActual={propietarioSeleccionado.precio}
        />
      ) : (
        <DivSeleccionHabitacion>
          <p>Selecciona un propietario</p>
          <img src={flechaAbajo} alt="Seleccione un propietario para administrarlo"/>
        </DivSeleccionHabitacion>
      )}
      <BarraBusqueda
        obtenerPropietariosFiltrados={obtenerPropietariosFiltrados}
      />
      {propietariosFiltrados.map((propietarioFiltrado, i) => (
        <ButtonPropietario
          key={i}
          onClick={() => seleccionarPropietario(i)}
        >
          <CardPropietario
            nombre={propietarioFiltrado.nombre}
            apellido={propietarioFiltrado.apellido}
            correo={propietarioFiltrado.correo}
            dni={propietarioFiltrado.dni}
            suscripcionActiva={propietarioFiltrado.suscripcionActiva}
            diasTotalAcumulados={propietarioFiltrado.diasTotalAcumulados}
            fechaRenovacionSuscripcion={propietarioFiltrado.fechaRenovacionSuscripcion}
            idPropietario={propietarioFiltrado.uid}
            bloqueado={propietarioFiltrado.bloqueado}
          />
        </ButtonPropietario>
      ))}
    </StyledSection>
  );
};
export default ListaPropietarios;
