import styled from "styled-components";
import { Link } from "react-router-dom";

const TextoEnlace = styled(Link)`
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
`;

export default TextoEnlace;
