import React, { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { AuthContextStudent } from "../../context/AuthStudent";
import { useParams } from "react-router-dom";
import CardPropiedadMapa from "../CardPropiedadMapa";
import IconMarcador from "../../assets/iconos/marcador.png"
import IconMarcadorSeleccionado from "../../assets/iconos/marcadorSeleccionado.png"

const containerStyle = {
  width: "100%",
  height: "75vh",
  margin: "50px",
};

function MapaStudent() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { currentUser } = useContext(AuthContextStudent);
  const propiedades = currentUser[1];


  let { ubicacion, latitudParam, longitudParam } = useParams();

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(14);

  const coordsPuno = { lat: -15.84054473927132, lng: -70.0276914112913 };
  const coordsMoquegua = { lat: -17.193877, lng: -70.934478 };
  const coordsArequipa = { lat: -16.398694704816343, lng: -71.53695703436338 };
  const coordsCuzco = { lat: -13.51656, lng: -71.97813 };
  const coordsParams = {
    lat: Number(latitudParam),
    lng: Number(longitudParam),
  };
  //****************************modificamos el centro del mapa**************** */
  useEffect(() => {
    if (latitudParam && longitudParam) {
      setCenter(coordsParams);
      setZoom(17);
    } else if (ubicacion == "Puno") {
      setCenter(coordsPuno);
    } else if (ubicacion == "Moquegua") {
      setCenter(coordsMoquegua);
    } else if (ubicacion == "Arequipa") {
      setCenter(coordsArequipa);
    } else if (ubicacion == "Cuzco") {
      setCenter(coordsCuzco);
    }
  }, [ubicacion]);

  //*******************manejamos datos y onClose del infoWindow**************** */
  const handleMarkerClick = (markerData) => {
    setSelectedMarker(markerData);
  };
  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };
  //***********cambiamos icono de Marker de propiedad seleccionada*********** */
  const renderMarkerIcon = (marker) => {
    if (marker.lat === center.lat && marker.lng === center.lng) {
      return {
        // Coloca la URL de la imagen para el marcador destacado
        url: IconMarcadorSeleccionado,
        // Ajusta el tamaño según sea necesario
        scaledSize: new window.google.maps.Size(40, 50),
      };
    } else {
      return {
        // Coloca la URL de la imagen para el marcador destacado
        url: IconMarcador,
        // Ajusta el tamaño según sea necesario
        scaledSize: new window.google.maps.Size(40, 50),
      };
    }
  };
  //**********************renderizamos mapa y markers*********************** */
  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom} options={{clickableIcons: false, gestureHandling: "greedy"}}>
      {propiedades.map((propiedad, i) => {
        if(propiedad.suscripcionActiva==true && propiedad.disponibilidadPropiedad==true){
          return(
          <Marker
            key={i}
            position={{ lat: propiedad.latitud, lng: propiedad.longitud }}
            icon={renderMarkerIcon({
              lat: propiedad.latitud,
              lng: propiedad.longitud,
            })}
            onClick={() =>
              handleMarkerClick({
                lat: propiedad.latitud,
                lng: propiedad.longitud,
                direccion: propiedad.direccion,
                distrito: propiedad.distrito,
                imagen: propiedad.imagen,
                idPropiedad: propiedad.idPropiedad,
                preciosHabitaciones: propiedad.preciosHabitaciones,
              })
            }
          />
          )
        }
      })}

      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={handleCloseInfoWindow}
        >
          <CardPropiedadMapa
            direccion={selectedMarker.direccion}
            urlImagen={selectedMarker.imagen}
            idPropiedad={selectedMarker.idPropiedad}
            preciosHabitaciones={selectedMarker.preciosHabitaciones}
          />
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default MapaStudent;
