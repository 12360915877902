import React from 'react';

const EnviarWhatsapp = ({ numero, mensaje }) => {
  const urlWhatsapp = `https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`;

  return (
    <a href={urlWhatsapp} target="_blank" rel="noopener noreferrer">
      Enviar mensaje a WhatsApp
    </a>
  );
};

export default EnviarWhatsapp;