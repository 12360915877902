import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fondo, colorPrimario } from '../../../components/UI/variables'
import mascota from "../../../assets/imagenes/mascota.png"

import Titulo2 from "../../../components/Titulo2"
import BoxContacto from "../../../components/BoxContacto"
import Boton from "../../../components/Boton"
import HeaderLoginOwner from '../../../components/HeaderLoginOwner'

const StyledSection = styled.section`
  padding-top: 200px;
  background-color: ${fondo};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const StyleDiv = styled.div`
  margin-bottom: 180px;
`
const StyleDivInstrucciones = styled.div`
  font-size: 14px;
  margin: 0 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
`
const StyledDivInstruccion = styled.p`
    display: flex;
    align-items: center;
    height: 24px;
    width: 330px;
`
const StyledPNumero = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 20px;
    background-color: ${colorPrimario};
    width: 36px;
    height: 36px;
    color: white;
    font-size: 20px;
`
const StyledPInstruccion = styled.p`
    font-weight: bold;
    margin-left: 16px;
    width: 272px;
`
const StyledP = styled.p`
  margin-left: 56px;
  margin-top: 0px;
`
const StyledImg = styled.img`
  height: 128px;
`
const StyledBoton=styled(Boton)`
  width: 328px;
  background-color: ${fondo};
  color: ${colorPrimario};
  border: 1px solid ${colorPrimario};
`
const Contacto = ({usuarioActual}) => {
  const [usuarioActualConfirmado, setUsuarioActualConfirmado] = useState(false)
  useEffect(()=>{
    if(usuarioActual){
      setUsuarioActualConfirmado(true)
    }
  },[usuarioActual])
  return (
    <div>
      {usuarioActualConfirmado ? <HeaderLoginOwner/> : <></>}
      <StyledSection>
        <StyleDiv>
          <Titulo2 $naranja>Bienvenido</Titulo2>
          <StyleDivInstrucciones>
            <BoxContacto/>
            <StyledDivInstruccion>
                <StyledPNumero>2.</StyledPNumero>
                <StyledPInstruccion>Verifica tu cuenta</StyledPInstruccion>
            </StyledDivInstruccion>
            <StyledP>Para verificar tu cuenta, haz click en el link que enviamos a tu correo electronico y listo. No olvides revisar en Spam o no deseados </StyledP>
            <StyledDivInstruccion>
                <StyledPNumero>3.</StyledPNumero>
                <StyledPInstruccion>Inicia sesion y empieza a administrar tus alquileres!</StyledPInstruccion>
            </StyledDivInstruccion>
            <StyledImg src={mascota} alt='Mascota de Arkilados - Perro Cuartelero'/>
            <StyledBoton to="/">Ir a pagina principal </StyledBoton>
          </StyleDivInstrucciones>
        </StyleDiv>
      </StyledSection>
    </div>
  )
}

export default Contacto