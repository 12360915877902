import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import { useContext } from 'react'
import { AuthContextOwner } from "../../../context/AuthOwner";

import Boton from '../../../components/Boton'
import Titulo2 from '../../../components/Titulo2'
import ContadorHabitaciones from '../../../components/ContadorHabitaciones'
import ListaHabitacionesAdministradasPagos from '../../../components/ListaHabitacionesAdministradasPagos';
import mascota from "../../../assets/imagenes/mascota.png"
import { colorPrimario } from '../../../components/UI/variables';
import BoxContacto from '../../../components/BoxContacto';

const StyledSection = styled.section`
  //background-color: ${fondo};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
  background-color: ${fondo};
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const StyledDivInstruccion = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`
const StyledPNumeroInstruccion = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 13px;
  border: solid 2px black;
  background-color: black;
  margin-right: 8px;
  font-weight: bold;
  color: white;
`
const StyledPInstruccion = styled.p`
  width: 312px;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const DivDesplegable = styled.div`
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  width: 360px;
`
const StyledSelect = styled.select`
  margin: 0px 0 8px 0;
  height: 36px;
  text-align: center;
`
const StyledSectionDefault = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledDivDefault=styled.div`
  display: flex;
  align-items: center;
  margin: 100px 8px 0px 8px;
 
  div{
    display: flex;
    flex-direction: column;
  }
  p{
    display: flex;
    flex-direction:column;
    justify-content: center;
    font-size: 18px;
    border: solid 2px ${colorPrimario};
    border-radius: 16px;
    padding: 8px;
    margin: 8px 0px 0px 0px;
  }
  img{
    width: 140px;
    height: 200px;
  }
`
const ControlarPagos = () => {
  const[totalHabitaciones, setTotalHabitaciones]= useState("")
  
  const { currentUser } = useContext(AuthContextOwner);
  const suscripcionActiva=currentUser[0].suscripcionActiva;
  const propiedadesOwner=currentUser[1]
  const [propiedadSeleccionada, setPropiedadSeleccionada] = useState("")
  
  const manejarSeleccion=(e)=>{
    setDireccionSeleccionada(e.target.value)
  }
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(propiedadesOwner[0]?.direccion || "")

  useEffect(()=>{
    propiedadesOwner.map((propiedadOwner)=>{
      if(propiedadOwner.direccion==direccionSeleccionada){
        setPropiedadSeleccionada(propiedadOwner)
      }
    })
  },[direccionSeleccionada])

  const contarHabitaciones=(totalHabitaciones) =>{
    setTotalHabitaciones(totalHabitaciones)
  }

  return (
    suscripcionActiva
    ?
    <section>
      {propiedadesOwner.length<1
      ?
      <StyledSectionDefault>
        <StyledHeader>
          <Boton to="/HomeOwnerLogin">Inicio</Boton>
          <TituloStyled $naranja>Control de Cobros</TituloStyled>
        </StyledHeader>
        <StyledDivDefault>
          <div>
            <p>Antes de registrar los pagos de tus inquilinos: Primero debes agregar una propiedad</p>
            <p>Regresa a inicio y entra a la seccion "Registra tus propiedades"</p>
          </div>
          <img src={mascota} alt='Mascota de Arkilados - Perro Cuartelero'/>
        </StyledDivDefault>
      </StyledSectionDefault>
      :
      <StyledSection>
        <StyledHeader>
          <Boton to="/HomeOwnerLogin">Inicio</Boton>
          <TituloStyled $naranja>Control de Cobros</TituloStyled>
        </StyledHeader>
        <StyledDivInstruccion>
          <StyledPNumeroInstruccion>1.</StyledPNumeroInstruccion>
          <StyledPInstruccion>Para iniciar Selecciona tu propiedad</StyledPInstruccion>
        </StyledDivInstruccion>
        <DivDesplegable>
          <StyledSelect value={direccionSeleccionada} onChange={manejarSeleccion}>
            {propiedadesOwner.map((propiedadOwner, index) => (
              <option value={propiedadOwner.direccion} key={index} >
                {propiedadOwner.direccion}
              </option>
            ))}
          </StyledSelect>
        </DivDesplegable>
        <StyledDivInstruccion>
          <StyledPNumeroInstruccion>2.</StyledPNumeroInstruccion>
          <StyledPInstruccion>Selecciona una habitacion para registrar un pago</StyledPInstruccion>
        </StyledDivInstruccion>
        <StyledDiv>
          {/* <ContadorHabitaciones totalHabitaciones={totalHabitaciones} imagen={propiedadSeleccionada.imagen}/> */}
          <ListaHabitacionesAdministradasPagos contarHabitaciones={contarHabitaciones} propiedadSeleccionada={propiedadSeleccionada}/>
        </StyledDiv>
      </StyledSection>
      }
    </section>
    :
    <StyledSection>
      <StyledHeader>
        <Boton to="/HomeOwnerLogin">Inicio</Boton>
        <TituloStyled $naranja>Control de Cobros</TituloStyled>
      </StyledHeader>
      <h1>Hola, se acabaron tus dias de suscripcion!!</h1>
      <p>Nos encantaria tenerte de vuelta, por favor contactanos para renovar tu suscripcion</p>
      <BoxContacto/>
    </StyledSection>
  )
}

export default ControlarPagos